import React from "react"
import Footer from "../../components/Footer/Footer";

const styles = require("../../styles/pages/General.module.scss");

export default function Hipaa() {
    return (
        <div>

            <div className={styles.CoverImage} style={{backgroundImage:'url("/assets/hipaa-medical-header.png")'}}>
                <h1>Prestavi is committed to compliance with HIPAA.</h1>
                <p>The federal Health Insurance Portability and Accountability Act (HIPAA) provides strict regulations to protect health information. </p>
            </div>
            <section>

                <h3>Keeping your data secure and compliant</h3>

                <div className={styles.ShortLine}></div>
                <p>We believe that privacy is a <span className={styles.Highlight}>fundamental human right</span> and understand the central role that it plays in modern software. We take a security first approach to every aspect of our business and are committed to ensuring that your company's data is always protected and safe with us.</p>

                <h2>Our Role in HIPAA Compliance</h2>
                <p>Prestavi regards itself as performing the functions of a <strong>Business Associate</strong>.</p>
                <p>Wherever necessary, Prestavi will enter into a Business Associate Agreement that provides contractual assurances that we will safeguard your electronic protected health information (ePHI).</p>
                <p>Prestavi uses vendors that are willing to enter into HIPAA-compliant agreements for situations where we store or transmit ePHI to them.</p>

                <h2>How does Prestavi comply with HIPAA?</h2>
                <p>We’ve devoted significant resources towards our efforts to comply with HIPAA, listed below are some of the steps that we’ve taken.</p>

                <div className={styles.Checklist}>
                    <h5>ADMINISTRATIVE SAFEGUARDS</h5>
                    <p>Risk Analysis completed.</p>
                    <p>Implemented security measures to reduce risk and vulnerabilities.</p>
                    <p>Implemented an employee sanction policy and we have trained our workforce on HIPAA and cyber security essentials.</p>
                    <p>Automated threat detection is used to continuously monitor and review our infrastructure audit logs for security risks and incidents.</p>
                    <p>Implemented policies for workforce security and access management.</p>
                    <p>Implemented an Incident Response Plan.</p>
                </div>

                <div className={styles.Checklist}>
                    <h5>PHYSICAL SAFEGUARDS</h5>
                    <p>ePHI is only stored with our HIPAA-compliant Cloud Service Provider.</p>
                    <p>Database backups are taken daily with point-in-time recovery.</p>
                    <p>ePHI is only accessible to workforce members that have the highest level of authorization.</p>
                    <p>We implement the principle of least privilege to ensure that workforce members only have access to the data they need to carry out their duties.</p>
                </div>

                <div className={styles.Checklist}>
                    <h5>TECHNICAL SAFEGUARDS</h5>
                    <img style={{margin: "16px 0 16px 0"}} src="/assets/aws-logo.png" width="120px" alt="AWS Logo"/>
                    <p>Enterprise grade security powered by Amazon Web Services.</p>
                    <p>All data sent over public networks use strong encryption. Our SSL certificates provided by AWS use the <strong>SHA-256</strong> hashing algorithm with RSA 2048-bit encryption.</p>
                    <p>While at rest, data that resides in our systems is encrypted using the industry-standard <strong>AES-256</strong> encryption algorithm.</p>
                    <p>All passwords are hashed and salted using the Blowfish cipher. <strong>No one at our company can read user passwords</strong>, if you forget it, you must reset it.</p>
                </div>

                <div className={styles.Callout}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="30.538" viewBox="0 0 20 30.538">
                        <path d="M110.084,565.232v-7.281a9.089,9.089,0,0,0-18.178,0v7.281a10,10,0,1,0,18.178,0ZM101,572.091a2.685,2.685,0,1,1,2.685-2.685A2.685,2.685,0,0,1,101,572.091Zm5.439-11.076a10,10,0,0,0-10.877,0v-3.349a5.439,5.439,0,1,1,10.877,0Z" transform="translate(-90.995 -548.867)" fill="#9cb7f7"/>
                    </svg>

                    <p>Read our security white paper at&nbsp;<a target="_blank" href="https://prestavi.com/security">prestavi.com/security</a></p>
                </div>
            </section>

            <section>
                <h2>Business Associate Agreement</h2>
                <p>We will provide a signed Business Associate Agreement to organizations that sign up for our <strong>Enterprise plan</strong>.</p>
            </section>

            <section>
                <h2>Information Booklet</h2>
                <p>We’ve created an information booklet with the details about Prestavi's HIPAA compliance in an easy to share format.</p>
                <a href="https://cdn.prestavi.com/Prestavi+HIPAA+Information+Booklet.pdf" target="_blank" download>
                    <div className={styles.PDF}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="43.9" height="42.803" viewBox="0 0 43.9 42.803">
                            <g transform="translate(0 -3.2)">
                                <path d="M7.8,3.2H36.1A7.8,7.8,0,0,1,43.9,11V38.2A7.8,7.8,0,0,1,36.1,46H7.8A7.806,7.806,0,0,1,0,38.2V10.982A7.7,7.7,0,0,1,7.8,3.2Z" transform="translate(0 0)" fill="#b30b00"/>
                                <path d="M74.606,66.879c-2.057-2.194-7.662-1.234-9.017-1.1a30.325,30.325,0,0,1-3.823-4.92A21.406,21.406,0,0,0,63,54.3c0-2.057-.823-4.1-3-4.1a2.189,2.189,0,0,0-1.92,1.1c-.96,1.646-.549,4.92.96,8.331a54.348,54.348,0,0,1-3.823,9.017c-2.194.823-6.977,3-7.388,5.468a2.015,2.015,0,0,0,.686,1.92,2.567,2.567,0,0,0,1.92.686c2.863,0,5.742-3.96,7.8-7.525a44.1,44.1,0,0,1,6.84-1.783,10.587,10.587,0,0,0,7.114,3.137c1.92,0,2.606-.823,2.863-1.508A1.95,1.95,0,0,0,74.606,66.879Zm-1.92,1.371c-.137.549-.823,1.1-2.057.823a9.935,9.935,0,0,1-3.96-2.057,18.646,18.646,0,0,1,4.92-.137A1.246,1.246,0,0,1,72.687,68.25Zm-13.268-16.4a.754.754,0,0,1,.686-.411c.686,0,.823.823.823,1.508a19.554,19.554,0,0,1-.96,4.92C58.87,54.863,59.008,52.668,59.419,51.846ZM59.282,67.29a32.735,32.735,0,0,0,1.783-4.371,24.069,24.069,0,0,0,2.468,3.274A20.822,20.822,0,0,0,59.282,67.29Zm-4.645,3.154c-1.9,3-3.685,4.92-4.783,4.92a.824.824,0,0,1-.549-.137.907.907,0,0,1-.137-.823C49.305,73.307,51.5,71.8,54.636,70.444Z" transform="translate(-39.603 -38.943)" fill="#fff"/>
                            </g>
                        </svg>

                        <p>Download PDF Information Booklet</p>
                    </div>
                </a>

            </section>

            <section style={{paddingBottom:"24px"}}>
                <h2>Questions? We have answers.</h2>
                <p>
                    For any questions related to our HIPAA compliance, please reach out to us at <span className={styles.Highlight}>hipaa@prestavi.com</span>
                </p>
            </section>
            <Footer />
        </div>
    )
}